import React from "react"
import {
  faMoneyBill,
  faHome,
  faGlobeAmericas
} from "@fortawesome/free-solid-svg-icons"
import { formatCurrency } from "../utils/helper"
export const sections = [
  {
    name: "Your Purchase Cost",
    questions: [
      {
        label: "Which property are you planning on renewing? ",
        type: "options",
        buttons: ["Primary Home", "Investment Property", "Vacation Home"],
        iconButtons: [faHome, faMoneyBill, faGlobeAmericas]
      },
      {
        label: "When is your mortgage renewal date?",
        type: "date",
        stepHelp:
          "When is your current mortgage term ending? If you are unsure of the exact date, an estimate will do."
      }
    ]
  },
  {
    name: "Mortgage",
    questions: [
      {
        label: "What is the current estimated market value of the property?",
        type: "amount",
        stepHelp:
          "Please provide an estimate of the current market value of the property.",
        min: {
          amount: 100000,
          error: "Please enter a value between 100K - 5M."
        },
        max: {
          amount: 5000000,
          error: "Please enter a value between 100K - 5M."
        }
      },
      {
        label:
          "What is the Mortgage Balance on the current mortgage? How much do you currently carry?",
        type: "amount",
        min: {
          amount: 1,
          error: "Please enter a value above 0"
        },
        max: {
          prevFromIndex: 0,
          amount: (x, prev, prevOfPrev = 0) => 0.95 * prev,
          error: (x) =>
            `Please enter a value between 0 and 95% of current estimated market value of property (${formatCurrency(
              x
            )})`
        },
        stepHelp:
          "Please provide an estimate of the current mortgage balance you carry."
      },
      {
        label: "What is your current interest rate on your existing Mortgage?",
        type: "percentage",
        stepHelp:
          "Please provide an estimate of the current interest rate on your existing mortgage.",
        min: {
          amount: 0.1,
          error: "Please enter a value above 0.1%."
        },
        max: {
          amount: 19,
          error: "Please enter a value between 0 - 19%."
        }
      }
    ]
  },
  {
    name: "Almost There",
    title: "Just a few more questions to go",
    questions: [
      {
        label: "What term do you prefer?",
        type: "options",
        buttons: ["5 Years", "3 Years", "1 Year"]
      },
      {
        label: "What kind of rates do you prefer?",
        type: "options",
        buttons: ["Fixed", "Variable", "Not Sure"],
        stepHelp: (
          <span>
            Monthly payments remain the same with <strong>fixed rate</strong>{" "}
            mortgages. <br />
            <br />
            Mortgage payments may vary slightly every month with{" "}
            <strong>variable rate</strong> mortgages.
          </span>
        )
      }
    ]
  },
  {
    name: "Last Step",
    lastStepText:
      "After comparing more than 40 vendors we found the lowest rates for you.",
    completeText: "Where do we send the quote?",
    isLastStep: true
  },
  {
    name: "Success" // reserved keyword
  }
]
