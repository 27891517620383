import React from "react"
import Layout from "../components/Layout/Layout"
import PageTransitionContainer from "../components/PageTransitionContainer"
import Form from "../components/Form"
import Seo from "../components/Seo"
import { sections } from "../data/form-renew"

const Renew = (props) => {
  return (
    <PageTransitionContainer>
      <Layout location={props.location} page="renew">
        <Seo title="Renew Mortgage | Ratelock" location={props.location} />
        <Form idToUse="renew" label="Renew Mortgage" sections={sections} />
      </Layout>
    </PageTransitionContainer>
  )
}

export default Renew
